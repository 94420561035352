import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import FolderIcon from "@mui/icons-material/Folder";
import GroupRoundedIcon from "@mui/icons-material/GroupRounded";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { GridCheckIcon } from "@mui/x-data-grid-pro";
import clsx from "clsx";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import React from "react";
import { fetchCurrentProject } from "../../DashboardComponents/Form/FormQueries/useCurrentProject.jsx";
import { fetchQuestionnaire } from "../../QueryHooks/questionnaire/useQuestionnaire.jsx";
import * as defaultStyles from "../../styles/main-datagrid.module.css";
import { RequestStatusCell } from "../../UniversalDataGridHelper/dataGridComponents.jsx";
import "../styles/admin-data-grid.css";
import {
  ContactsPopover,
  EmailUsersPopover,
  ServicesPopover,
} from "./adminComponents.jsx";
dayjs.extend(customParseFormat);

// |ADMIN COLUMNS GENERATOR|
export function getColumns(
  dispatch,
  navigate,
  setClient,
  setClientID,
  setProjectID,
  queryClient,
  updateQuestionnaire,
  emailUserProps,
  servicesPopoverProps,
  contactsPopoverProps,
  updateArchive,
  checkQuestionnaire,
) {
  return [
    {
      field: "projectName",
      headerName: "Project",
      flex: 0.75,
      minWidth: 200,
      hideable: true,
      pinnable: false,
      resizable: false,
      headerAlign: "center",
      align: "center",
      headerClassName: defaultStyles.projectNameHeader,
      cellClassName: defaultStyles.projectNameCells,
      disableColumnMenu: true,
    },
    {
      field: "requestStatus",
      headerName: "Status",
      pinnable: false,
      sortable: true,
      hideable: true,
      flex: 0.45,
      headerClassName: defaultStyles.requestStatusHeader,
      align: "center",
      renderCell: (params) => {
        return <RequestStatusCell params={params} isAdmin={true} />;
      },

      cellClassName: (params) => {
        if (params.value === null) {
          return "";
        }
        return clsx({
          [defaultStyles.requestStatusCells]: true,
          [defaultStyles.inProgress]: params.value === "IP",
          [defaultStyles.incomplete]: params.value === "I",
          [defaultStyles.pending]: params.value === "P",
          [defaultStyles.revise]: params.value === "RV",
          [defaultStyles.completed]: params.value === "C",
        });
      },
      disableColumnMenu: true,
    },
    {
      field: "projectId",
      headerName: "Actions",
      flex: 0.4,
      sortable: false,
      hideable: true,
      pinnable: true,
      resizable: true,
      headerAlign: "center",
      align: "center",
      headerClassName: defaultStyles.actionHeader,
      disableColumnMenu: true,
      renderCell: (params) => {
        if (params.value && params.row.requestStatus === "P") {
          return (
            <Button
              variant="contained"
              id={"pendingButtonAdmin"}
              size="small"
              className={"pendingButton"}
              onClick={async () => {
                let mutationProp = {
                  clientId: params.row.clientId,
                  projectId: params.value,
                };

                dispatch(setClientID(mutationProp.clientId));
                dispatch(setProjectID(mutationProp.projectId));

                let questionnaireExists = await checkQuestionnaire(
                  mutationProp.clientId,
                  mutationProp.projectId,
                );

                console.log("Questionnaire Exists: ", questionnaireExists);

                if (questionnaireExists === 204) {
                  console.log(
                    "Questionnaire Doesn't Exists: ",
                    questionnaireExists,
                  );
                  await updateQuestionnaire.mutateAsync(mutationProp);
                }

                navigate(
                  `/dashboard/admin/validate-questionnaire/${mutationProp.clientId}/${mutationProp.projectId}`,
                );
              }}
            >
              Review
            </Button>
          );
        } else if (params.value && params.row.requestStatus === "IP") {
          return (
            <Button
              variant="contained"
              id={"inProgressButtonAdmin"}
              size="small"
              className={"inProgressButton"}
              onClick={async () => {
                dispatch(
                  setClient({
                    id: params.row.clientId,
                    name: params.row.clientName,
                    shortName: params.row.clientData.shortName,
                    address: params.row.clientData.address,
                    city: params.row.clientData.city,
                    zipCode: params.row.clientData.zipCode,
                    phone: params.row.clientData.phone,
                  }),
                );
                dispatch(setClientID(params.row.clientId));
                dispatch(setProjectID(params.value));
                let cId = params.row.clientId;
                let pId = params.value;
                await queryClient.prefetchQuery({
                  queryKey: ["currentProject", cId, pId],
                  queryFn: () => fetchCurrentProject(cId, pId),
                });

                let slideNumber = +params.row.recentSlide;

                navigate(`/dashboard/form/${cId}/${pId}/${slideNumber}`);
              }}
            >
              View
            </Button>
          );
        } else if (params.value && params.row.requestStatus === "I") {
          return (
            <Button
              variant="contained"
              id={"editButtonAdmin"}
              size="small"
              className={"editButton"}
              onClick={async () => {
                dispatch(
                  setClient({
                    id: params.row.clientId,
                    name: params.row.clientName,
                    shortName: params.row.clientData.shortName,
                    address: params.row.clientData.address,
                    city: params.row.clientData.city,
                    zipCode: params.row.clientData.zipCode,
                    phone: params.row.clientData.phone,
                  }),
                );
                dispatch(setClientID(params.row.clientId));
                dispatch(setProjectID(params.value));
                let cId = params.row.clientId;
                let pId = params.value;
                await queryClient.prefetchQuery({
                  queryKey: ["currentProject", cId, pId],
                  queryFn: () => fetchCurrentProject(cId, pId),
                });
                let slideNumber = +params.row.recentSlide;

                navigate(`/dashboard/form/${cId}/${pId}/${slideNumber}`);
              }}
            >
              Edit
            </Button>
          );
        } else if (params.row.requestStatus === "RV") {
          return (
            <Button
              variant="contained"
              id={"reviseButtonAdmin"}
              size="small"
              className={"reviseButton"}
              onClick={async () => {
                const projectId = params.value;
                const clientId = params.row.clientId;
                dispatch(
                  setClient({
                    id: clientId,
                    name: params.row.clientName,
                    shortName: params.row.clientData.shortName,
                    address: params.row.clientData.address,
                    city: params.row.clientData.city,
                    zipCode: params.row.clientData.zipCode,
                    phone: params.row.clientData.phone,
                  }),
                );
                dispatch(setClientID(clientId));
                dispatch(setProjectID(projectId));
                await queryClient.prefetchQuery({
                  queryKey: ["currentProject", clientId, projectId],
                  queryFn: () => fetchCurrentProject(clientId, projectId),
                });
                let recentSlide = +params.row.recentSlide;
                navigate(
                  `/dashboard/form/${clientId}/${projectId}/${recentSlide}`,
                );
              }}
            >
              Revise
            </Button>
          );
        } else if (params.row.requestStatus === "C") {
          return (
            <Button
              variant="contained"
              size="small"
              id={"downloadButtonAdmin"}
              className={"downloadButton"}
              onClick={async () => {
                setProjectID(params.value);
                setClientID(params.row.clientId);
                let questionnaire = await queryClient.fetchQuery({
                  queryKey: [
                    "questionnaire",
                    params.row.clientId,
                    params.value,
                  ],
                  queryFn: () =>
                    fetchQuestionnaire(params.row.clientId, params.value),
                });

                if (questionnaire) {
                  console.log("Questionnaire: ", questionnaire);
                  window.open(questionnaire.url, "_blank");
                }
              }}
            >
              Download
            </Button>
          );
        }
      },
    },
    {
      field: "servicesRequest",
      headerName: "Services",
      flex: 0.3,
      hideable: true,
      pinnable: false,
      sortable: false,
      headerClassName: defaultStyles.servicesHeader,
      align: "center",
      type: "string",
      valueGetter: (params) => {
        if (params.value === undefined) return;
        return params.value === true ? "Requested" : "Not Requested";
      },
      renderCell: (params) => {
        const { servicesAnchor, currServicesRow, openServicesPopoverHandler } =
          servicesPopoverProps;

        if (!params.value) return;

        return params.value === "Requested" ? (
          <>
            <Tooltip
              title="Click to view requested services"
              id="services-tooltip"
              placement="top"
              classes={{
                tooltip: "dashboard-tooltip",
                popper: "dashboard-tooltip-popper",
                arrow: "dashboard-arrow",
              }}
            >
              <IconButton
                aria-label="services-expand"
                key={`${params.id}-services-expand}`}
                className={defaultStyles.servicesButton}
                id={params.id}
                onClick={openServicesPopoverHandler}
              >
                <GridCheckIcon className={defaultStyles.boolIcon} />
              </IconButton>
            </Tooltip>
            {servicesAnchor && params.row.id === currServicesRow ? (
              <ServicesPopover params={params} />
            ) : null}
          </>
        ) : (
          <Tooltip
            title="No services have been selected"
            id="services-tooltip"
            placement="top"
            classes={{
              tooltip: "dashboard-tooltip",
              popper: "dashboard-tooltip-popper",
              arrow: "dashboard-arrow",
            }}
          >
            <HorizontalRuleIcon
              className={defaultStyles.boolIcon}
              id={defaultStyles.hrIcon}
            />
          </Tooltip>
        );
      },
      cellClassName: (params) => {
        return clsx({
          [defaultStyles.boolCol]: true,
          [defaultStyles.requested]: params.value === "Requested",
          [defaultStyles.notRequested]: params.value === "Not Requested",
        });
      },
      disableColumnMenu: true,
    },
    {
      field: "contacts",
      headerName: "Contacts",
      flex: 0.32,
      hideable: true,
      pinnable: false,
      resizable: false,
      sortable: false,
      headerAlign: "center",
      align: "center",
      headerClassName: defaultStyles.contactsHeader,
      disableColumnMenu: true,
      renderCell: (params) => {
        if (!params.value) return;

        const { contactsAnchor, currContactsRow, openContactsPopoverHandler } =
          contactsPopoverProps;
        return (
          <>
            <Tooltip
              title="Click to view contacts"
              id="contacts-tooltip"
              placement="top"
              classes={{
                tooltip: "dashboard-tooltip",
                popper: "dashboard-tooltip-popper",
                arrow: "dashboard-arrow",
              }}
              enterDelay={350}
            >
              <IconButton
                aria-label="contacts-expand"
                key={`${params.id}-contacts-expand}`}
                className={"contactsButton"}
                id={params.row.id}
                onClick={openContactsPopoverHandler}
                classes={{
                  root: "contactsButtonRoot",
                }}
              >
                <GroupRoundedIcon
                  id={"contactsIcon"}
                  className={"contactsIcon"}
                />
              </IconButton>
            </Tooltip>
            {contactsAnchor && params.row.id === currContactsRow ? (
              <ContactsPopover params={params} />
            ) : null}
          </>
        );
      },
    },
    {
      field: "emailId",
      headerName: "Invite",
      flex: 0.23,
      hideable: true,
      pinnable: false,
      resizable: true,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (!params.value) {
          return;
        }
        if (params.row.users && params.row.users.length === 0) {
          return <p>No Users</p>;
        }

        const {
          emailAnchor,
          currEmailRow,
          openEmailPopoverHandler,
          asyncSendEmail,
        } = emailUserProps;

        return (
          <>
            <Tooltip
              title={
                params.row.requestStatus !== "A" &&
                params.row.requestStatus !== "C" &&
                params.row.users.length > 0
                  ? "Click to select user to email with a link to access this project"
                  : params.row.requestStatus === "A" ||
                      (params.row.requestStatus === "C" &&
                        params.row.users.length > 0)
                    ? "This request is either completed or archived"
                    : "No Users"
              }
              id="services-tooltip"
              classes={{
                tooltip: "dashboard-tooltip",
                popper: "dashboard-tooltip-popper",
                arrow: "dashboard-arrow",
              }}
              placement="top"
              enterDelay={350}
            >
              <IconButton
                aria-label="email-user-expand"
                key={`${params.id}-email-user-expand}`}
                className={`${"emailUserButton"} ${
                  params.row.requestStatus === "A" ||
                  params.row.requestStatus === "C" ||
                  params.row.users.length === 0
                    ? "disabledEmailBtn"
                    : ""
                }`}
                classes={{
                  root: "emailUserButtonRoot",
                }}
                id={params.id}
                onClick={
                  params.row.requestStatus !== "A" &&
                  params.row.requestStatus !== "C" &&
                  params.row.users.length > 0
                    ? openEmailPopoverHandler
                    : () => {}
                }
              >
                <EmailRoundedIcon
                  id={"emailUserIcon"}
                  className={"emailUserIcon"}
                />
              </IconButton>
            </Tooltip>
            {emailAnchor && params.row.id === currEmailRow ? (
              <EmailUsersPopover
                params={params}
                asyncSendEmail={asyncSendEmail}
              />
            ) : null}
          </>
        );
      },
    },
    {
      field: "fileDetails",
      headerName: "Files",
      flex: 0.23,
      hideable: true,
      pinnable: false,
      resizable: false,
      sortable: false,
      headerAlign: "center",
      align: "center",
      headerClassName: defaultStyles.fileDetailsHeader,
      cellClassName: defaultStyles.fileDetailsCells,
      disableColumnMenu: true,
      valueGetter: (params) => {
        if (!params.value || params.value.length === 0) return undefined;
        return [params.row.clientId, params.row.projectId];
      },
      renderCell: (params) => {
        if (!params.value) return;
        const [client, project] = params.value;
        if (client && project) {
          return (
            <Tooltip
              title={
                params.row.requiredFilesCount > 0
                  ? `${params.row.requiredFilesCount} files have been marked as required and must be uploaded to fully complete request. Click to view files.`
                  : "Click to view files"
              }
              id="files-tooltip"
              placement="top"
              classes={{
                tooltip: "dashboard-tooltip",
                popper: "dashboard-tooltip-popper",
                arrow: "dashboard-arrow",
              }}
            >
              <IconButton
                variant="contained"
                size="small"
                id={"fileDetailsButton"}
                className={"fileDetailsButton"}
                onClick={async () => {
                  dispatch(setProjectID(project));
                  dispatch(setClientID(client));
                  navigate(`/dashboard/files/${client}/${project}`);
                }}
              >
                <Badge
                  badgeContent={params.row.requiredFilesCount}
                  color="rgb(198, 42, 42)"
                  variant={`${window.innerWidth < 1630 ? "dot" : "standard"}`}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  className={defaultStyles.filesBadgeCount}
                  classes={{
                    root: defaultStyles.filesBadgeRoot,
                    badge: defaultStyles.filesBadge,
                    dot: defaultStyles.filesBadgeDot,
                  }}
                >
                  <FolderIcon id={"fileDetailsIcon"} />
                </Badge>
              </IconButton>
            </Tooltip>
          );
        }
      },
    },
    {
      field: "archived",
      headerName: "Archive",
      flex: 0.25,
      minWidth: 114,
      type: "boolean",
      hideable: true,
      pinnable: false,
      resizable: false,
      sortable: false,
      headerAlign: "center",
      align: "center",
      headerClassName: defaultStyles.archiveHeader,
      cellClassName: defaultStyles.archiveCells,
      disableColumnMenu: true,
      renderCell: (params) => {
        const projectId = params.row.projectId;
        const projectName = params.row.projectName;
        return params.value === false ? (
          <Tooltip
            title="Click to archive this request for all users"
            id="archive-tooltip"
            placement="top"
            classes={{
              tooltip: "dashboard-tooltip",
              popper: "dashboard-tooltip-popper",
              arrow: "dashboard-arrow",
            }}
          >
            <IconButton
              aria-label="archive-icon-button"
              variant="contained"
              id={defaultStyles["archive-btn"]}
              className={`${defaultStyles["archive-btn"]} ${
                params.row.requestStatus !== "C"
                  ? defaultStyles["disable-archived"]
                  : ""
              }`}
              disableRipple
              onClick={async () => {
                await updateArchive.mutateAsync({
                  projectId: projectId,
                  archive: true,
                  projectName: projectName,
                });
              }}
            >
              <BookmarkBorderIcon id={defaultStyles["unarchivedIcon"]} />
            </IconButton>
          </Tooltip>
        ) : params.value === true ? (
          <Tooltip
            title="This request has been archived for all users. Click to unarchive."
            id="archive-tooltip"
            placement="top"
            classes={{
              tooltip: "dashboard-tooltip",
              popper: "dashboard-tooltip-popper",
              arrow: "dashboard-arrow",
            }}
          >
            <IconButton
              aria-label="archive-icon-button"
              variant="contained"
              id={defaultStyles["unarchive-btn"]}
              className={defaultStyles["archive-btn"]}
              disableRipple
              onClick={async () => {
                await updateArchive.mutateAsync({
                  projectId: projectId,
                  archive: false,
                  projectName: projectName,
                });
              }}
            >
              <BookmarkIcon id={defaultStyles["archivedIcon"]} />
            </IconButton>
          </Tooltip>
        ) : null;
      },
    },
    {
      field: "savedAt",
      type: "dateTime",
      headerName: "Recent",
      pinned: false,
      hideable: true,
      width: 0,
      minWidth: 0,
      resizable: false,
      flex: 0,
      sortable: false,
      disableColumnMenu: true,
    },
  ];
}
